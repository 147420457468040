import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Map } from "../components/map"
import AboutQuanticCoffee from "../components/sections/aboutQuanticCoffee"
import QuanticInfo from "../components/sections/quantic_info"
import Contact from "../components/sections/contact"
import { Container, Header } from "semantic-ui-react"
import { injectIntl } from "gatsby-plugin-intl"

const SecondPage = ({ intl }) => (
  <Layout>
    <ParallaxProvider>
      <SEO title="About Us" />
      <AboutQuanticCoffee />
      <QuanticInfo />
      <Container
        fluid
        content
        textAlign="center"
        style={{ backgroundColor: "#CBA06D", minHeight: "95vh" }}
      >
        <Header
          style={{ padding: "2em" }}
          className="title"
          as="h1"
          content={intl.formatMessage({ id: "quantic_info.map_title" })}
        />
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA_hz9RMtDw3Y7OMgUbyrJpCwC6j-ZZ_00&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          ubi={{ lat: 4.96102, lng: -75.701523 }}
          title="Casa Quantic"
        />
      </Container>
      <Contact />
    </ParallaxProvider>
  </Layout>
)

export default injectIntl(SecondPage)
