import React from "react"
import { Container, Grid, Header, Item } from "semantic-ui-react"
import { Parallax } from "react-scroll-parallax"
import { Carousel } from "react-responsive-carousel"
import Image from "../image"
import { injectIntl } from "gatsby-plugin-intl"
import "../layout.css"

const AboutQuanticCoffee = ({ intl }) => {
  const images = [
    { image: "AQC2.JPG", name: "AQC2" },
    { image: "AQC3.JPG", name: "AQC3" },
    { image: "AQC4.JPG", name: "AQC4" },
    { image: "AQC1.JPG", name: "AQC1" },
  ]
  return (
    <Container
      fluid
      content
      style={{ backgroundColor: "#CBA06D", minHeight: "95vh" }}
    >
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center" width={9}>
            <Header
              style={{ padding: "2em" }}
              className="title"
              as="h1"
              content={intl.formatMessage({ id: "about_quantic_coffee.title" })}
            />
            <Container text textAlign="justified">
              <p style={{ fontFamily: "Glacial", fontSize: "1.6em" }}>
                {intl.formatMessage({ id: "about_quantic_coffee.text_1" })}
              </p>
              <p style={{ fontFamily: "Glacial", fontSize: "1.6em" }}>
                {intl.formatMessage({ id: "about_quantic_coffee.text_2" })}
              </p>
            </Container>
          </Grid.Column>
          <Grid.Column style={{ padding: "2.5em" }} width={7}>
            <Parallax y={[0, 20]} tagOuter="figure">
              <Carousel infiniteLoop showThumbs={false} showStatus={false}>
                {images &&
                  images.map(({ image, name }) => (
                    <Item.Group key={name}>
                      <Item>
                        <Item.Image size="big" style={{ margin: "2em 1.5em" }}>
                          <Image
                            src={image}
                            alt={name}
                            width={500}
                            height={100}
                          />
                        </Item.Image>
                      </Item>
                    </Item.Group>
                  ))}
              </Carousel>
            </Parallax>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default injectIntl(AboutQuanticCoffee)
